<template>
  <div>404 / Страница не найдена</div>
</template>

<script>
export default {
  async asyncData(app, context) {
    context.res.status(404);
  },
};
</script>
